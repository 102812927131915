.consult-presentation {
  display: flex;
  margin: auto;
  width: 832px;
  @media only screen and (max-width: 480px) {
    width: 376px;
    display: block;
  }
}
.header {
  padding: 70px
}
