*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

body{
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  background: #0D0D0F !important;
}
a{
  text-decoration: none !important;
}

li{
  list-style: none;
}
h1,h2,h3{
  color: rgb(245, 236, 219);
}
center{
  margin-top: 30px;
}


.navbar{
  background-color: #161616 !important;
}


#search-box:focus{
  outline: 2px solid #00CE79 !important;
outline: none;
}

.movie-card{
  transition: all .3s ease-in-out;
}
.movie-card:hover{
  transform: scale(1.02);
  box-shadow: 10px 10px 10px rgba(0,0,0,.1);
}

.basic-text{
  color: white;
}

#badge{
  /* background-color:#222222 !important; */
  /* background-color:#00CE79 !important;
  color:black; */
}


@media screen and (max-width:800px) {
  #movie-info{
    flex-direction: column;
  }
  #form{
    width: 100% !important;
  }
}

.paginate{
  display:flex;
  gap: 0 12px;
  color: white;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}

.paginate>li{
  background-color: #252525;
  font-size: 18px;
  padding: .3em .6em;
  border-radius: 5px;
  transition: all .2s linear;
}

.paginate>li:hover{
  background-color: #00CE79;
  color: black;
}

.selected{
  background-color:#00CE79  !important;
  color: black;
}

.paginate > .previous>a{
  color: white ;
}
.paginate > .next>a{
  color: white ;
  
}
.paginate > .previous>a:hover{
  color: black ;
}
.paginate > .next>a:hover{
  color: black ;
}




.search-box{
  background: #1e1e1e !important;
  display: none;
  border: none !important;
  color: white !important;
}