.consultCard {
  width: 26rem;
  margin: 20px;
  display: flex;
  flex-flow: column;

  .backside {
    width: 26rem;
    height: 56rem;
    background-color: #afafaf;

    .center-bottom {
      background-color: white;
      border-radius: 6px;
    }
  }

  .card {
    width: 20rem;
    margin: 20px;
    height: 56rem;
  }

  .experience {
    max-height: 51rem;
    overflow-y: scroll;
  }
}

.card-img-top {
  height: 318px;
}


.center-bottom {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
