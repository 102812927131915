.presentation-area {
  padding: 80px;
}
.basic-text{
  margin: 5%;
}
.focus-area{
  padding-top: 80px;
  display: flex;
  @media only screen and (max-width: 832px){
    display: block;
  }
}
.focus-area:last-child{
  margin-bottom: 200px;
}
h1,h2,h3{
  text-align: center  ;
}
.focus-header {
  margin: auto;
  width: 50%;
  @media only screen and (max-width: 832px){
    width: 100%;
  }
}
.focus-body {
  width: 50%;
  margin: 5%;
  @media only screen and (max-width: 832px){
    width: 100%;
  }
}
.box {
  width: 30%;
  display:inline-block;
  margin:10px 0;
  border-radius:5px;
}
.text {
  cursor: pointer;
  padding: 10px 0;
  font-weight:bold;
  text-align:center;
  h3 {
    color:white;
  }
}
#links {
  white-space:nowrap;
  text-align:center;
}
.graph{
  padding-top: 0;
  .horizontal-line {
    margin-top: -20px;
    margin-left: 10%;
    margin-right: 10%;
    height: 50px;
    width: 80%;
    display: inline-block;
    border-bottom: dashed white 4px;
    border-left: dashed white 4px;
    border-right: dashed white 4px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .vertical-line{
    margin-left: 2px;
    width: 50%;
    height: 100px;
    margin-top: -50px;
    margin-bottom: -50px;
    border-right: dashed white 5px;;
  }
}

.anchor {
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 40px;
  height: 100px;
}
